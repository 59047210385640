import axios from "axios";

export interface Department {
  name: string;
  description?: string;
  level?: number;
  team?: Team[];
}

export interface Team {
  name: string;
  position?: string;
  level?: number;
  contact?: Contact;
}

export interface Contact {
  email?: string;
  phone?: string;
}



export async function getDepartmentData(): Promise<Department[]> {
  try {
    const { data } = await axios.get(
      "https://services.smartrancagua.com/cdir/department"
    );

    const { rows } = data;

    // Filtrar los departamentos para excluir "Área Jurídica"
    const filteredDepartments = rows.filter(
      (department: any) => department.name !== "Área Jurídica"
    );

    // Mapeo correcto de los departamentos filtrados
    const departments: Department[] = filteredDepartments.map((department: any) => {
      return { ...department, team: department.team };
    });

    console.log("Filtered Departments:", departments);
    return departments;
  } catch (error) {
    console.error("Error fetching department data:", error);
    return [];
  }
}


export const getTeamBanner = () => [
  {
    src: "/banner_corporacion.png",
    alt: "corporacion"
  }
];
